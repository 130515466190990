import { DOCUMENT } from '@angular/common';
import {
  afterNextRender,
  Component,
  effect,
  inject,
  input,
  InputSignal,
} from '@angular/core';
import { SafeUrl, DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-courses-form',
  standalone: true,
  imports: [],
  templateUrl: './courses-form.component.html',
  styleUrl: './courses-form.component.scss',
})
export class CoursesFormComponent {
  public id: InputSignal<number> = input(0);
  public url = input('' as string);
  public iFrameUrl?: SafeUrl;
  public randomId = Math.random().toString(36).substring(7);
  private readonly document = inject(DOCUMENT);
  private readonly domSanitizer = inject(DomSanitizer);

  constructor() {
    console.log(this.url());
    effect(() => {
      this.iFrameUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(
        this.url()
      );
    });
    afterNextRender(() => {
      const iframe = this.document.getElementById(
        this.randomId
      ) as HTMLIFrameElement;
      console.log('iframe:', iframe);
      if (!iframe) return;
      iframe.onload = () => {
        try {
          console.log('iframe loaded');
          const iframeDocument =
            iframe.contentDocument || iframe.contentWindow?.document;
          const iframeHeight = iframeDocument?.documentElement.scrollHeight;
          iframe.style.height = iframeHeight + 'px';
        } catch (error) {
          console.error('Error accessing iframe content:', error);
        }
      };
    });
  }
}
