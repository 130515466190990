<app-small-header [title]="title"></app-small-header>
<div class="content">
  <div class="background">
    <div class="container">
      @if(level?.teaser && level?.teaser !== '') {
      <h2>{{ level?.teaser }}</h2>
      }
      <div class="desc" [innerHtml]="levelDescription"></div>
      @if(level && !level.showForm) {
      <app-courses-table [courses]="courses"></app-courses-table>
      } @else if(level && level.showForm && level.formUrl && level.formUrl !==
      '') {
      <app-courses-form
        [id]="level.id"
        [url]="level.formUrl.toString()"
      ></app-courses-form>
      }
    </div>
  </div>
</div>
