import { Component, inject, LOCALE_ID } from '@angular/core';
import { CommonModule, registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { SmallHeaderComponent } from '../../../_helpers/small-header/small-header.component';
import { Cours, Level } from '../../../_models/nimbuscloud.model';
import { ActivatedRoute } from '@angular/router';
import { NimbuscloudService } from '../../../_services/nimbuscloud.service';
import { DomSanitizer, Meta, SafeHtml } from '@angular/platform-browser';
import { DateTime } from 'luxon';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { CoursesTableComponent } from '../courses-table/courses-table.component';
import { CoursesFormComponent } from '../courses-form/courses-form.component';

registerLocaleData(localeDe);

@Component({
  selector: 'app-courses-detail',
  standalone: true,
  imports: [
    CommonModule,
    SmallHeaderComponent,
    MatDialogModule,
    MatIconModule,
    MatButtonModule,
    CoursesTableComponent,
    CoursesFormComponent,
  ],
  providers: [
    {
      provide: LOCALE_ID,
      useValue: 'de',
    },
  ],
  templateUrl: './courses-detail.component.html',
  styleUrl: './courses-detail.component.scss',
})
export class CoursesDetailComponent {
  public title: string = 'Kursdetails';
  public id: string = '';
  public typeId: string = '';

  public level?: Level;
  public levelDescription?: SafeHtml;

  public courses: Cours[] = [];

  private readonly forms = [
    {
      id: 50,
      url: 'https://ts-schwandner.nimbuscloud.at/?r=/forms/public/show-form/7',
    },
    {
      id: 51,
      url: 'https://ts-schwandner.nimbuscloud.at/?r=/forms/public/show-form/1',
    },
    {
      id: 52,
      url: 'https://ts-schwandner.nimbuscloud.at/?r=/forms/public/show-form/6',
    },
  ];

  constructor(
    route: ActivatedRoute,
    private readonly nimbus: NimbuscloudService,
    private readonly dialog: MatDialog,
    private readonly meta: Meta,
    private readonly sanitizer: DomSanitizer
  ) {
    route.paramMap.subscribe((params) => {
      let id = params.get('id');
      if (id && id !== '') {
        this.id = id;
      }
      let typeId = params.get('typeId');
      if (typeId && typeId !== '') {
        this.typeId = typeId;
      }
      let title = params.get('title');
      if (title && title !== '') {
        this.title = title;
      }
      setTimeout(() => {
        this.getTypes();
        this.getCourses();
      });
    });
  }

  ngOnInit(): void {
    this.meta.updateTag({
      name: 'description',
      content:
        this.title +
        ' - ADTV Tanzschule Seidl Amberg - Standard Latein (Welttanzprogramm), Hochzeitskurse, Privatstunde, Discofox, Salsa, Hip Hop, Ballett, Zumba, Kinder-Teenie-Tanzen, Linedance uvm',
    });
  }

  getTypes() {
    this.nimbus.getTypes().subscribe((data) => {
      if (data.statuscode === 200) {
        const type = data.content.types.find((t) => +t.id === +this.typeId);
        const level = type?.levels.find((l) => +l.id === +this.id);
        if (level?.description) {
          // remove style attributes
          this.levelDescription = this.sanitizer.bypassSecurityTrustHtml(
            level.description
              .replace(/(<[^>]+) style=".*?"/i, '$1')
              .replace(/(<[^>]+) [^>]+/gi, '$1')
          );
        }
        if (level?.id) {
          const url = this.forms.find((f) => f.id === +level.id)?.url;
          if (url) {
            level.formUrl = url;
            level.showForm = true;
          } else {
            level.showForm = false;
          }
        } else if (level) {
          level.showForm = false;
        }
        this.level = level;
        this.loadMeta();
      }
    });
  }

  loadMeta() {
    if (this.level) {
      let description = this.level.description.replace(/<(.|\n)*?>/g, '');
      description = description
        .replace('&amp;', '&')
        .replace(/[&]nbsp[;]/gi, ' ')
        .replace(/[<]br[^>]*[>]/gi, '')
        .replace(/[\n\t]+/g, '')
        .replace(/\s+/g, ' ')
        .trim();
      const teaser = this.level.teaser
        ? this.level.teaser
        : this.level.externalName;
      this.meta.updateTag({
        name: 'description',
        content: teaser + ' - ' + description + ' - Tanzen in Amberg',
      });
    }
  }

  getCourses() {
    this.nimbus.getCourses(this.typeId, this.id).subscribe((data) => {
      if (data.statuscode === 200) {
        this.courses = data.content.courses;
        this.courses.forEach((c) => {
          if (+c.startEventDate)
            c.startEventDate = DateTime.fromMillis(+c.startEventDate * 1000)
              .setLocale('de')
              .setZone('Europe/Berlin')
              .toFormat('EEEE, dd.MM.yyyy');
          if (+c.startEventStartTime)
            c.startEventStartTime = DateTime.fromMillis(
              +c.startEventStartTime * 1000
            )
              .setLocale('de')
              .setZone('Europe/Berlin')
              .toFormat('HH:mm');
          if (+c.startEventEndTime)
            c.startEventEndTime = DateTime.fromMillis(
              +c.startEventEndTime * 1000
            )
              .setLocale('de')
              .setZone('Europe/Berlin')
              .toFormat('HH:mm');
        });
      }
    });
  }
}
